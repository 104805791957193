import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard } from '@fortawesome/free-regular-svg-icons';
import { mobile, tabletL } from 'styles/responsive';
import Template from 'components/templates/MainTemplate';
import Footer from 'components/organisms/footer/Footer';

const PortfolioContent = styled.div`
  position: relative;
  height: 100vh;
  margin: 30px 30px 30px 0;
  padding: 50px 0;
  border: var(--main-content-border);
  border-radius: 10px;
  background: var(--main-content-bg);
  box-shadow: var(--main-content-shadow);

  @media (max-width: ${mobile}) {
    margin: 0;
    padding: 20px;
    border-radius: 0;
  }
`;

const PortfolioInfo = styled.div`
  display: flex;
  gap: 20px;
  width: 75%;
  margin: 80px auto 0 auto;
  font-size: 30px;
  font-weight: 600;

  @media (max-width: ${tabletL}) {
    margin-top: 50px;
  }

  @media (max-width: ${mobile}) {
    margin: 0;
    font-size: 24px;

    svg {
      margin-left: 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 75%;
  margin: 50px auto 0 auto;
  font-size: 20px;
  font-weight: 600;

  p {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  a:hover {
    color: var(--point-color);
  }
`;

export default function PortfolioPage() {
  return (
    <Template url={`https://nxnaxx.github.io/portfolio`}>
      <PortfolioContent>
        <PortfolioInfo>
          <FontAwesomeIcon icon={faIdCard} />
          Portfolio
        </PortfolioInfo>
        <Content />
        <Footer />
      </PortfolioContent>
    </Template>
  );
}
